import * as React from 'react'
import Navbar from './components/Navbar'
import Footer from './components/Footer'

export default function partnersPage () {
	return (
		<main>
			<Navbar></Navbar>
			<div className='partners'>

				<h1>Partners</h1>

			</div>
			<Footer></Footer>
		</main>
	)
}